import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import './views/dashboard/css/DB.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const ClassTable = React.lazy(() => import('./views/pages/classTable/ClassTable'));
const Invoice = React.lazy(() => import('./views/dashboard/pages/payment/Invoice'));
const Fattura = React.lazy(() => import('./views/dashboard/pages/payment/Fattura'));
const Bill = React.lazy(() => import('./views/dashboard/pages/payment/Bill'));
const PublicHome = React.lazy(() => import('./views/public/PublicHome'));
const VerifyCert = React.lazy(() => import('./views/public/VerifyCert'));
const Welcome = React.lazy(() => import('./views/pages/welcome/Welcome'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Forgetpassword = React.lazy(() => import('./views/pages/forgetpass/Forgetpassword'));
const DetailsColletion = React.lazy(() => import('./views/pages/collectionForm/DetailsColletion'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/forgetpassword" name="Forget Password Page" render={props => <Forgetpassword {...props} />} />
            <Route exact path="/detailsform" name="Collection Page" render={props => <DetailsColletion {...props} />} />
            <Route exact path='/welcome' name='Welcome Page' render={props => <Welcome {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/timetable" name="Register Page" render={props => <ClassTable {...props} />} />
            <Route exact path="/cert/verify/:certId" name="RIn Page" render={props => <VerifyCert {...props} />} />
            <Route exact path="/fattura/:pdoc/:st" name="RIn Page" render={props => <Fattura {...props} />} />
            <Route exact path="/bill/:doc" name="RIn Page" render={props => <Bill {...props} />} />
            <Route exact path="/public/:id" name="Public Home Page" render={props => <PublicHome {...props} />} />
            <Route exact path="/invoice/:doc/:st" name="RIn Page" render={props => <Invoice {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
